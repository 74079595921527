/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, {useState, useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Alert,
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormSelect,
} from "shards-react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import { accessCheck } from "../../utils/tools";

import PageTitle from "../../components/common/PageTitle";
import moment from "moment-timezone";

let defaultAvatar = require('../../images/blank_avatar.png')

const Locations = (props) => {

  const [locations, setLocations] = useState([]);
  const [status, setStatus] = useState('all');
  const [client, setClient] = useState('all');
  const [addedLocation, setAddedLocation] = useState(props.addedLocation);
  const [updatedLocation, setUpdatedLocation] = useState(props.updatedLocation);
  const [tempImage, setTempImage] = useState(null)
  

  useEffect(() => {
    props.getLiveLocations(props.user.id)
    document.title = 'Locations: EnforcePlus admin';
    !accessCheck(props.user, 'locations') && (window.location.href = '/') 
  }, [])

  useEffect(() => {
    setLocations(props.locations)
  }, [props.locations])

  useEffect(() => {
    if (props.addedLocation) {
      setAddedLocation(true);
      setTimeout(() => {
        setAddedLocation(false)
        props.clearAddedLocation()
      }, 10000);
    }
    if (props.updatedLocation) {
      setUpdatedLocation(true);
      setTimeout(() => {
        setUpdatedLocation(false)
        props.clearAddedLocation()
      }, 10000);
    }
  }, [props.addedLocation, props.updatedLocation])

  const filterLocations = () => {
    let sortedLocations = locations;
    if (status !== 'all') {
      if (status === 'active') {
        sortedLocations = sortedLocations.filter(location => location.active)
      } else {
        sortedLocations = sortedLocations.filter(location => !location.active)
      }
    }
    if (client !== 'all') {
      sortedLocations = sortedLocations.filter(location => location.client === client)
    }
    sortedLocations = sortedLocations.sort((a, b) => a.name && b.name && a.name.localeCompare(b.name));
    return sortedLocations
  }

  const cloneLocation = (location) => {
    let confirmation = window.confirm('Are you sure you want to clone this location?')
    if (confirmation) {
      props.cloneLocation(location)
    }
  }

  return (
    <Container fluid className="main-content-container px-0">
      {addedLocation && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.location.name} has been succesfully added!
        </Alert>
      )}
      {updatedLocation && (
        <Alert className="mb-0" theme={'success'}>
          <i className="fa fa-info mx-2"></i> {props.location.name} has been succesfully updated!
        </Alert>
      )}
      <Container fluid className="main-content-container px-4">
        {/* Page Header */}

        {/* First Row of Posts */}
        <Row noGutters className="page-header py-4">
          <PageTitle xs="12" sm="4" title="Locations" className="text-sm-left mb-3" />
          <Col xs="12" sm="8" className='text-right'>
            <Row>
              <Col xs="1"></Col>
              <Col className='text-right pl-0 pr-1'>
                {props.user.type === 'super' && (
                  <FormSelect id="feClients" onChange={event => setClient(event.target.value)}>
                    <option value={'all'}>All clients</option>
                    {props.clients?.map(client => (
                      <option value={client.id}>{client.name}</option>
                    ))}
                  </FormSelect>
                )}
              </Col>
              <Col className='text-right pl-0 pr-0'>
                <FormSelect id="feInputState" onChange={event => setStatus(event.target.value)}>
                  <option value={'all'}>All statuses</option>
                  <option value={'active'}>Active</option>
                  <option value={'inactive'}>Inactive</option>
                </FormSelect>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col lg="3" md="6" sm="12" className="mb-4">
            <Link to={'/add-location'} style={{textDecoration: 'none'}}>
              <Card small className="card-post card-post--1" style={{minHeight: '331px'}}>
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url(${require('../../images/large_plus.png')})`, backgroundSize: 'auto'}}>
                  </div>
                <CardBody className="text-center" style={{paddingTop: '1rem', color: '#3D3C3E'}}>
                  {tempImage && (
                    <img src={`${tempImage}`} alt=""/>
                  )}
                  <h7 className="card-title mb-3">
                    Add Location
                  </h7>
                  <p className="card-text mt-3" style={{ fontSize: '12px', color: '#9EA0A5'}}>
                    Add a new location to start issuing citations or warnings.
                  </p>
                </CardBody>
              </Card>
            </Link>
          </Col>
          {props.loadingLocations && props.locations.length === 0 && (
            <Col className="mb-4 pl-4">
              <Row>
                <Col className="mb-4 pl-3">
                  <p>&nbsp;Loading locations...</p>
                </Col>
              </Row>
            </Col>
          )}
          {filterLocations().length === 0 && props.loadedLocations && (
            <Col className="mb-4 pl-4">
              <Row>
                <Col className="mb-4 pl-3">
                  <p>&nbsp;No locations found</p>
                </Col>
              </Row>
            </Col>
          )}
          {filterLocations().map((location, idx) => {
            return (
              <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
                <Link to={'/permitted-vehicles/' + location.id} style={{textDecoration: 'none'}}>
                  <Card small className="card-post" style={{minHeight: '331px'}}>
                    <CardBody style={{borderBottom: '1px solid #EEEFF1'}}>
                      <h7 className="card-title mb-3" style={{fontSize: 16, color: '#5a6169'}}>{location.name}</h7>
                      <p style={{color: '#9EA0A5', fontSize: 12}}>{location.address1}<br />
                      {!!location.address2 && (
                        <>
                          {location.address2}<br />
                        </>
                      )}
                      {location.city}<br />
                      {location.state} {location.zip}<br /></p>
                      {location.last_scan ? (
                        <p style={{color: '#9EA0A5', fontSize: 12}}>LAST UPDATED {moment(location.last_scan).fromNow().toUpperCase()}</p>
                      ) : (<p style={{color: '#9EA0A5', fontSize: 12}}>&nbsp;</p>)}
                    </CardBody>
                    <CardBody>
                      <Row>
                        <Col>

                          <span style={{color: '#9EA0A5', fontSize: 12}}>LAST SCANNED BY</span>
                        </Col>
                        <Col xs={2} style={{fontSize: 16, color: '#9EA0A5'}} >
                          <Link className={'location-icon'} onClick={() => cloneLocation(location)} to="#" style={{textDecoration: 'none', color: '#9EA0A5'}}>
                            <ContentCopyIcon fontSize="inherit" />
                          </Link>
                        </Col>
                      </Row>
                      <Row className={'mt-2'}>
                        <Col className={'text-nowrap'}>
                          {!!location.last_scan_user ? (
                            <>
                              <img className="user-avatar rounded-circle mr-2" src={defaultAvatar} alt="User Avatar" style={{height: 50}}/>
                              <span className="d-inline-block" style={{fontSize: 14, color: '#9EA0A5'}}>{props.users.find(user => user.id === location.last_scan_user)?.name}</span>
                            </>
                          ) : (
                            <>
                              <img className="user-avatar rounded-circle mr-2" src={defaultAvatar} alt="User Avatar" style={{height: 50}}/>
                              <span className="d-inline-block" style={{fontSize: 14, color: '#9EA0A5'}}>None recorded</span>
                            </>
                          )}
                          
                        </Col>
                        <Col xs={2} style={{textDecoration: 'none', color: '#9EA0A5', paddingTop: 12}}>
                          <BorderColorIcon fontSize="inherit" />
                        </Col>
                      </Row>
                      
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            )
          })}
        </Row>
      </Container>
    </Container>
  );
}

export default Locations;
